@font-face {
    font-family: 'SongMyung';
    src: url('SongMyung-Regular.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSerifKR';
    src: url('NotoSerifKR-VariableFont_wght.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

.MuiStepLabel-label {
    font-family: 'NotoSerifKR', sans-serif !important; /* 우선순위 높이기 */
}

.MuiButton-root {
    font-family: 'NotoSerifKR', sans-serif !important; /* !important로 우선순위 높이기 */
}